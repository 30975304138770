import axios from "axios";
import { baseURL } from "../config/index.js";

export const api = axios.create({
  baseURL,
});

// api.interceptors.response.use((res) => {
//   if (res.data.code === 200) {
//     return res.data;
//   } else {
//     message.error(res.data.message);
//     return null;
//   }
// });
