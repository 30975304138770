import img1 from "../images/common/team6.png";
import img2 from "../images/common/team7.png";
import img3 from "../images/common/team8.png";
import img4 from "../images/common/team9.png";
import img5 from "../images/common/team10.png";
import img6 from "../images/common/team11.png";

import com0 from "../images/company/0.png";
import com1 from "../images/company/1.png";
import com2 from "../images/company/2.png";
import com3 from "../images/company/3.png";
import com4 from "../images/company/4.png";
import com5 from "../images/company/5.png";
import com6 from "../images/company/6.png";
import com7 from "../images/company/7.png";
import com8 from "../images/company/8.png";
import com9 from "../images/company/9.png";

const dataTeam = [
  {
    id: 1,
    img: img1,
    name: "Leo Whitennig",
    position: "Co-founder & Coo",
    companies: [],
    listsocial: [
      {
        id: 0,
        icon: com9,
        link: "#",
      },
      {
        id: 1,
        icon: com0,
        link: "#",
      },
      {
        id: 2,
        icon: com5,
        link: "#",
      },
      {
        id: 2,
        icon: com3,
        link: "#",
      },
    ],
  },
  {
    id: 2,
    img: img2,
    name: "Hans",
    position: "IP producer & consultant",
    listsocial: [
      {
        id: 0,
        icon: com9,
        link: "#",
      },
      {
        id: 1,
        icon: com1,
        link: "#",
      },
      {
        id: 2,
        icon: com7,
        link: "#",
      },
    ],
  },
  {
    id: 3,
    img: img3,
    name: "Sundararajan  Patel",
    position: "Blockchain Architect",
    listsocial: [
      {
        id: 0,
        icon: com9,
        link: "#",
      },
      {
        id: 1,
        icon: com2,
        link: "#",
      },
    ],
  },
  {
    id: 4,
    img: img4,
    name: "Arlo Bancroft",
    position: "Blockchain Developer",
    listsocial: [
      {
        id: 0,
        icon: com9,
        link: "#",
      },
      {
        id: 1,
        icon: com8,
        link: "#",
      },
    ],
  },
  {
    id: 5,
    img: img5,
    name: "Miranda Hall",
    position: "Game Programmer",
    listsocial: [
      {
        id: 0,
        icon: com9,
        link: "#",
      },
      {
        id: 1,
        icon: com6,
        link: "#",
      },
    ],
  },
  {
    id: 6,
    img: img6,
    name: "Peter Teng",
    position: "IP commercialization consultant",
    listsocial: [
      {
        id: 0,
        icon: com9,
        link: "#",
      },
      {
        id: 1,
        icon: com4,
        link: "#",
      },
    ],
  },
];

export default dataTeam;
