
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import { Web3ContextProvider } from "./context/Web3Context"
import '../src/assets/font/font-awesome.css'
import routes from './pages';
import Page404 from './pages/404';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
      }, []);

    return (
        <Web3ContextProvider>

            <Header />

            <Routes>

                {
                    routes.map((data,idx) => (
                        <Route key={idx} path={data.path} element={data.component} exact />
                    ))
                }

                <Route path='*' element={<Page404 />} />
            </Routes>
            <ToastContainer position="top-right" />

            <Footer />
        </Web3ContextProvider>
    );
}

export default App;
