const dataAbout = [
    {
        id: 1,
        title: 'Sailing with One Piece in Web3',
        desc: 'Setting off the Web3 Journey with One Piece by collecting limited NFTs. Unexpected gameplays and interactions await you to unlock.',
        // class: 'active'
    },
    {
        id: 2,
        title: '5 Exciting Storylines to Explore',
        desc: `In the NFT collection of ONE PIECE: KEEP SAILING, there are 5 exciting storylines for NFT enthusiasts to get in: ‘Straw Hat Pirates’, ‘On top of the war’, ‘Wano Country(Kimono)’, ‘Dressrosa’ and ‘Whole Cake Island Arc’. Each storyline will contain 9 common NFTs and one special rare NFT. More details about storylines are coming!`,
    },
    {
        id: 3,
        title: 'Special Limited-edition Rare Box',
        desc: `If you are greatly passionate about ONE PIECE NFT collection, don’t miss out the special limited-edition rare box, delicately created by Shueisha. More information about rare boxes will be revealed soon!`
    },
]

export default dataAbout;