const dataAbout = [
    {
        id: 1,
        title: 'Remove Threshold for IP/Brands/Web3 projects to Enter the NFT Field',
        desc: `With a DID-aggregated system, BOARD will automatically analyze all about the IP/brands/Web3 projects and work out a project-focused and market-targeted NFT launch strategy. To become the gateway for them to enter the NFT field, BOARD develops NFT Booster: NFT Launchpad, NFT Marketplace and NFT IDO for their smooth soft-launch.
        `
    },
    {
        id: 2,
        title: 'Fully Boost IP Monetization in Web3 sector',
        desc: `
        As an IP/Brand-driven NFT market, BOARD provides a series of Booster products and IP-derivatives rewarding programs to literally realize IP monetization and push it forward. Furthermore, BOARD will utilize the decentralized power of BOARD DAO to innovate, create and explore more unexpected possibilities of IP derivatives and embrace diversity.
        `
    },
    {
        id: 3,
        title: 'Enrich NFT Application Scenarios including DeFi, GameFi, Metaverse',
        desc: `
        NFT soft-launch is just the first step for IP/brands/Web3 projects to enter the Web3 sector. For the long-term growth and value-added ecosystem development, BOARD will help enrich NFT application scenarios in the range of DeFi, GameFi, Metaverse, etc.
        `
    },
]

export default dataAbout;