const dataRoadmap = [

    



    {
        id: 1,
        time: '2023 Q1',
        list: [
            {
                text: 'Twitter/Discord/Medium Get Ready'
            },
            {
                text: 'Official Website Goes Live'
            },
            {
                text: 'Global Marketing Kick-off'
            },
            {
                text: 'Litepaper V1.0 Release'
            },
            {
                text: 'NFT Launchpad Under Development'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: '2023 Q2',
        list: [
            {
                text: 'BOARD Incubator Grant Release(Partner with Top-tier Companies/Funds/IPs)'
            },
            {
                text: 'Make Partnerships with Top-tier IP/Brands'
            },
            {
                text: '1st BOARD Launchpad Launch'
            },
            {
                text: 'BOARD DAO Goes Live'
            },
            {
                text: 'Make Partnerships with Fiat-Crypto Payment Provider'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: '2023 Q3',
        list: [
            {
                text: 'OAT Giveaway Event Launch'
            },
            {
                text: 'DID System Under Development'
            },
            {
                text: 'Ethereum-based NFT Marketplace Under Development'
            },
            {
                text: 'BOARD DAO-IP Derivative Creation Event Launch'
            },
            {
                text: 'BOARD Hackathon Event Launch'
            },
            {
                text: 'BOARD DAO Educational Rewarding Program Launch'
            },
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: '2023 Q4',
        list: [
            {
                text: 'IDO Under Development'
            },
            {
                text: 'Multi-chain Deployment'
            },
            {
                text: 'Build IPCO Global Alliance'
            },
            {
                text: '$BOARD IDO/Listing Launch'
            },
            {
                text: 'Make BOARD&IP Presence in Metaverse'
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: '2024 Q1',
        list: [
            {
                text: 'BOARD EarlyBird Plus Program Launch'
            },
            {
                text: 'BOARD PASS Staking Goes Live'
            },
            {
                text: 'Make Partnerships with Leading Exchanges'
            },
            {
                text: 'BOARD DAO Development Rewarding Program Launch'
            },
        ],
        positon: 'left'
    },
]

export default dataRoadmap;