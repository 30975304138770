import React from 'react'
const links = [
  {
    title: "Board",
    list: [
      {
        title: "Launchpad",
        href: "",
      },
      {
        title: "Marketplace",
        href: "",
      },
      {
        title: "APP Download",
        href: "",
      },
      {
        title: "Community",
        href: "",
      },
    ],
  },
  {
    title: "About",
    list: [
      {
        title: "Whitepaper",
        href: "",
      },
      {
        title: "Audit Report",
        href: "",
      },
      {
        title: "Privacy Policy",
        href: "",
      },
      {
        title: "Terms of Service",
        href: "",
      },
    ],
  },
  {
    title: "Opportunities",
    list: [
      {
        title: "You are a Creator",
        href: "",
      },
      {
        title: "Collaboration Application",
        href: "",
      },
    ],
  },
];

export default function Footer() {
  return (
    <footer className="bg-[#141A31] pt-16 pb-11 px-8 border-t border-[rgba(185,185,191,.2)]">
      <div className="mx-auto max-w-[815px] 2xl:max-w-7xl">
        <div className="flex flex-wrap justify-between items-start">
          {links.map((item, index) => (
            <div key={index}>
              <div className="text-white text-lg 2xl:text-4xl mb-4 2xl:mb-6">{item.title}</div>
              {item.list.map((subItem, subIndex) => (
                <div className="text-[17px] 2xl:text-[24px] text-[rgb(185,185,191)] mb-4 2xl:mb-6" key={subIndex}>
                  {subItem.title}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="text-[11px] 2xl:text-lg text-[rgba(255,255,255,.4)] mt-16">
          © 2023 Board All Rights Reserved. All names, logos, images, and brands
          are property of their respective owners. Non-Affiliation and
          Disclaimer
        </div>
      </div>
    </footer>
  );
}
