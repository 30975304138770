import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import menus from "../../pages/menu";
import useWeb3Context from '../../hooks/useWeb3Context'
import { shortenAddr } from '../../lib/tool'
import "./styles.scss";
import logo from "../../assets/images/logo/logo.svg";
import Button from "../button";

const Header = () => {
  const { connectWallet, account } = useWeb3Context();
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  return (
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo flex items-center">
            <NavLink to="/">
              <img src={logo} alt="Crybox" className="w-32" />
            </NavLink>
          </div>
          <nav
            id="main-nav"
            className={`main-nav ${menuActive ? "active" : ""}`}
          >
            <ul id="menu-primary-menu" className="menu">
              {menus.map((data, idx) => (
                <li
                  key={idx}
                  onClick={() => handleDropdown(idx)}
                  className={`menu-item ${
                    data.namesub ? "menu-item-has-children" : ""
                  } ${activeIndex === idx ? "active" : ""}`}
                >
                  {data.linkType === "internal" ? (
                    <a href={data.links}>{data.name}</a>
                  ) : data.linkType === "external" ? (
                    <a href={data.links} target="_blank">
                      {data.name}
                    </a>
                  ) : (
                    <Link to={data.links}>{data.name}</Link>
                  )}

                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map((submenu) => (
                        <li key={submenu.id} className="menu-item">
                          {submenu.linkType === "external" ? (
                            <a href={submenu.links} target="_blank">
                              {submenu.sub}
                            </a>
                          ) : (
                            <NavLink to={submenu.links}>{submenu.sub}</NavLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          {account ? (
            <Button title={shortenAddr(account, 4)} />
          ) : (
            <Button onClick={() => {connectWallet()}} title="connect wallet" />
          )}

          <div
            className={`mobile-button ${menuActive ? "active" : ""}`}
            onClick={handleMenuActive}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
